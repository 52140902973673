import { Container, Stack } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import type { NextPage } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Board } from "../common/boards/board.schema";
import { useAppStore } from "../common/stores/app.store";
import { useAuthStore } from "../common/stores/auth.store";
import FeaturedBoards from "../components/boards/FeatureBoards";
import { Features } from "../components/layout/Features";
import { HomeHero } from "../components/layout/HomeHero";
import LiveMatches from "../components/matches/LiveMatches";

interface Props {
  boards: Board[];
  count: number;
}
const Home: NextPage<Props> = ({}) => {
  const token = useAuthStore((state) => state.token);
  const router = useRouter();
  useEffect(() => {
    if (token) router.replace("/dashboard");
  }, [router, token]);
  const isPwa = useAppStore((s) => s.isPwa);
  useEffect(() => {
    if (isPwa && !token) router.replace("/pwa-home");
  }, [isPwa, router, token]);
  // if (data) board = data;
  return (
    <ModalsProvider>
      <>
        <HomeHero />
        <Features />
        <Container p="md">
          <Stack>
            <FeaturedBoards />
            <LiveMatches featuredOnly />
          </Stack>
        </Container>
      </>
    </ModalsProvider>
  );
};
interface StaticProps {
  locale: string;
}
export async function getStaticProps({ locale }: StaticProps) {
  // const { records, count } = await listBoards();
  // console.log("boards static :", boards);
  return {
    revalidate: 60,
    props: {
      // boards: records,
      // count,
      ...(await serverSideTranslations(locale, ["common", "home"])),
    },
  };
}

export default Home;
